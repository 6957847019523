import LoaderComponent from './components/shared/loader/loader.component.vue'
const styles = [
    'color: #ffeb3b',
    'background: #474141',
    'font-size: 13px',
    'border: 1px solid #ffeb3b',
    'padding: 2px 5px',
    'border-radius: 5px'
].join(';');

export default {
    computed: {
        isLogin() {
            return this.$route.name === "login";
        },
    },
    created() {
        console.log(`%cversion: ${require('../package.json').version}`,styles);

        this.$store.dispatch('initAuth').then((res) => {
            if (res) {
                // console.log("init auth + ");
            }
        })
    },
    components: {
        LoaderComponent
    },
}
