export default [
    {
        path: '/',
        name: 'public',
        meta: {
            name: 'Public'
        },
        redirect: {
            name: 'statistics'
        },
        component: () => import(/* webpackChunkName: "public" */ '../components/layout/public/public.component.vue'),
        children: [
            {
                path: '/statistics',
                name: 'statistics',
                meta: {
                    name: 'Statistika'
                },
                component: () => import(/* webpackChunkName: "statistics" */ '../views/statistics/statistics.component.vue')
            },
            {
                path: '/lead',
                name: 'lead',
                meta: {
                    name: 'Səhifə təsviri'
                },
                component: () => import(/* webpackChunkName: "lead" */ '../views/lead/lead.component.vue')
            },
            {
                path: '/about',
                name: 'about',
                meta: {
                    name: 'Səhifə təsviri'
                },
                component: () => import(/* webpackChunkName: "about" */ '../views/about/about.component.vue')
            },
            {
                path: '/contact',
                name: 'contact',
                meta: {
                    name: 'Əlaqə'
                },
                component: () => import(/* webpackChunkName: "contact" */ '../views/contact/contact.component.vue')
            },
            {
                path: '/skills',
                name: 'skills',
                meta: {
                    name: 'Biz kimik?'
                },
                component: () => import(/* webpackChunkName: "skills" */ '../views/skills/skills.component.vue')
            },
            {
                path: '/reports',
                name: 'reports',
                meta: {
                    name: 'Hesabat'
                },
                component: () => import(/* webpackChunkName: "reports" */ '../views/reports/reports.component.vue')
            },
            {
                path: '/feature',
                name: 'feature',
                meta: {
                    name: 'Xüsusiyyətlət'
                },
                component: () => import(/* webpackChunkName: "feature" */ '../views/feature/feature.component.vue')
            },
            {
                path: '/settings',
                name: 'settings',
                meta: {
                    name: 'Tənzimləmələr'
                },
                component: () => import(/* webpackChunkName: "settings" */ '../views/settings/settings.component.vue')
            },
            {
                path: '/project',
                name: 'project',
                meta: {
                    name: 'Layihələr'
                },
                component: () => import(/* webpackChunkName: "project" */ '../views/project/project.component.vue')
            },

            // add and edit page
            {
                path: '/skills-add',
                name: 'skills-add',
                meta: {
                    name: 'Biz kimik?'
                },
                component: () => import(/* webpackChunkName: "skills-add" */ '../views/skills-add/skills-add.component.vue')
            },
            {
                path: '/skills-edit/:id',
                name: 'skills-edit',
                meta: {
                    name: 'Biz kimik?',
                    edit: true
                },
                component: () => import(/* webpackChunkName: "skills-add" */ '../views/skills-add/skills-add.component.vue')
            },
            {
                path: '/reports-add',
                name: 'reports-add',
                meta: {
                    name: 'Hesabat'
                },
                component: () => import(/* webpackChunkName: "reports-add" */ '../views/reports-add/reports-add.component.vue')
            },
            {
                path: '/reports-edit/:id',
                name: 'reports-edit',
                meta: {
                    name: 'Hesabat',
                    edit: true
                },
                component: () => import(/* webpackChunkName: "reports-add" */ '../views/reports-add/reports-add.component.vue')
            },
            {
                path: '/feature-add',
                name: 'feature-add',
                meta: {
                    name: 'Xüsusiyyətlər'
                },
                component: () => import(/* webpackChunkName: "feature-add" */ '../views/feature-add/feature-add.component.vue')
            },
            {
                path: '/feature-edit/:id',
                name: 'feature-edit',
                meta: {
                    name: 'Xüsusiyyətlər',
                    edit: true
                },
                component: () => import(/* webpackChunkName: "feature-add" */ '../views/feature-add/feature-add.component.vue')
            },
            {
                path: '/project-add',
                name: 'project-add',
                meta: {
                    name: 'Təkliflər'
                },
                component: () => import(/* webpackChunkName: "project-add" */ '../views/project-add/project-add.component.vue')
            },
            {
                path: '/project-edit/:id',
                name: 'project-edit',
                meta: {
                    name: 'Təkliflər',
                    edit: true
                },
                component: () => import(/* webpackChunkName: "project-add" */ '../views/project-add/project-add.component.vue')
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            name: 'Login'
        },
        component: () => import(/* webpackChunkName: "tournaments" */ '../components/layout/login/login.component.vue'),
    },

    {
        path: "/:catchAll(.*)",
        name: "NotFound",
        redirect: {
            name: 'public'
        },
    },
];
