import axios from "axios";
import api from '@/core/api';

export default {
    login(_, payload) {
        return axios
            .post(api.login, payload)
            .then((response) => {
                const res = response.data;
                let token = res.token;
                localStorage.setItem(`admin.${process.env.VUE_APP_NAME}`, token);
                location.reload();
                return true;
            })
            .catch(() => {
                return false;
            });
    },

    changePass(_, payload) {
        return axios
            .post(api.settings, payload)
            .then((response) => {
                if (response && response.status === 200) {
                    return true;
                }
            })
            .catch(() => {
                return false;
            });
    },

}
