import HeaderComponent from './components/header/header.component.vue'
import LeftMenuComponent from './components/left-menu/left-menu.component.vue'

export default {
    name: "public",
    computed: {
        isLogin() {
            return this.$route.name === "login";
        },
    },
    components: {
        HeaderComponent,
        LeftMenuComponent,
    },
}
