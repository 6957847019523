import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueToast from 'vue-toast-notification';
import './components/shared/shared';

import "@/assets/styles/index.scss";
import './core/interceptors';
// import './components/shared/shared';
import 'vue-toast-notification/dist/theme-sugar.css';

import { VueEditor } from "vue2-editor";
import DatetimePicker from 'vuetify-datetime-picker';


Vue.use(VueToast);
Vue.use(DatetimePicker);
Vue.component("vue-editor", VueEditor);

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");
