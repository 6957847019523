export default {
    props: {
        propFile: {
            type: String | File,
        },
        refresh: {
            type: Boolean,
        },
    },
    data() {
        return {
            file: null
        }
    },
    computed: {
        showFileIcon() {
            return this.propFile || this.file ? 'primary' : ''
        }
    },
    methods: {
        clickInput() {
            this.$refs.fileInput.click();
        },
        changeFile(e) {
            let file = e.target.files[0];
            this.file = file;
            this.$emit("new-file", file);
        },
    },
    watch: {
        refresh(val) {
            if (val === true) {
                this.file = null;
                this.$refs.fileInput.value = "";
            }
        },
    },

}
