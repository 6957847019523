import ReportsPostModel from "@/views/reports/models/reports-post.model";
import fileInput from "@/mixins/file-input";
import getLanguages from "@/mixins/get-languages";

export default {
    name: "reports-add",
    data() {
        return {
            item: {}
        }
    },
    computed: {
        isEditPage() {
            return this.$route.meta.edit;
        }
    },
    methods: {
        approve() {
            let data = new ReportsPostModel(this.item);
            let formData = new FormData();
            for (let key in data) {
                formData.append(key, data[key]);
            }

            if (this.isEditPage) {
                this.$store.dispatch("reports/updateItem", {id: data.id, data: formData});
            } else {
                this.$store.dispatch("reports/setItem", formData).then((res) => {
                    if (res) {
                        this.item = {};
                        this.resfresh = true;
                    }
                });
            }
        },

    },
    created() {
        let id = this.$route.params.id;
        if (this.isEditPage) {
            this.$store.dispatch("reports/getItemById", id).then((res) => {
                if (res) {
                    this.item = res;
                }
            });
        }
    },
    mixins: [fileInput,getLanguages]
};
