export default {
    data() {
        return {
            items: [
                {
                    title: "Statistika",
                    icon: "mdi-poll",
                    link: "statistics",
                },
                {
                    title: "Səhifə təsviri",
                    icon: "mdi-image",
                    link: "lead",
                },
                {
                    title: "Haqqımızda",
                    icon: "mdi-information-variant",
                    link: "about",
                },
                {
                    title: "Layihələr",
                    icon: "mdi-folder-multiple-image",
                    link: "project",
                },
                {
                    title: "Biz kimik?",
                    icon: "mdi-account-supervisor",
                    link: "skills",
                },
                {
                    title: "Hesabat",
                    icon: "mdi-file-chart",
                    link: "reports",
                },
                {
                    title: "Xüsusiyyətlər",
                    icon: "mdi-format-list-bulleted-square",
                    link: "feature",
                },
                {
                    title: "Əlaqə",
                    icon: "mdi-phone",
                    link: "contact",
                },
            ],
        };
    },
};
