export default {
    data() {
        return {
            resfresh: true,
        };
    },
    methods: {
        changeFile(e) {
            this.resfresh = false;
            this.item.file = e;
        },
    },
};
