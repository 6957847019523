import {mapGetters} from "vuex";
import AboutPostModel from "@/views/about/models/about-post.model";
import getLanguages from "@/mixins/get-languages";

export default {
    name: "about",
    data() {
        return {
            type: null,
            item: {},
        }
    },
    computed: {
        ...mapGetters({
                aboutTypes: 'about/_getItems',
            }
        )
    },
    methods: {
        getAbout() {
            this.$store.dispatch('about/getItemById', this.type).then((res) => {
                if (res) {
                    this.item = {...res};
                }
            })
        },
        updateAbout() {
            let payload = new AboutPostModel({...this.item, type: this.type});
            this.$store.dispatch('about/updateItem', payload)
        }
    },
    created() {
        this.$store.dispatch('about/getItems');
    },
    mixins:[getLanguages]

};
