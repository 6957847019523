import login from '../components/layout/login/store';
import statistics from '../views/statistics/store';
import lead from '../views/lead/store';
import about from '../views/about/store';
import contact from '../views/contact/store';
import skills from '../views/skills/store';
import reports from '../views/reports/store';
import feature from '../views/feature/store';
import project from '../views/project/store';

export default {
    login,
    statistics,
    lead,
    about,
    contact,
    skills,
    reports,
    feature,
    project,

}
