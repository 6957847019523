export default class StatisticsModel {
    constructor(item) {
        this._setId(item);
        this._setProjects(item);
        this._setBuildings(item);
        this._setCustomers(item);

    }
    /**
     * set id
     * @param id
     * @private
     */
    _setId({id}) {
        this.id = id;
    }
    /**
     * set projects
     * @param finishedProjectCount
     * @private
     */
    _setProjects({finishedProjectCount}) {
        this.projects = finishedProjectCount;
    }

    /**
     * set buildings
     * @param finishedBuildingCount
     * @private
     */
    _setBuildings({finishedBuildingCount}) {
        this.buildings = finishedBuildingCount;
    }

    /**
     * set customers
     * @param customerCount
     * @private
     */
    _setCustomers({customerCount}) {
        this.customers = customerCount;
    }

}