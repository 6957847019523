import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes.js";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return {x: 0, y: 0};
    },
});

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem(`admin.${process.env.VUE_APP_NAME}`);
    if (token && token.length) {
        if (to.name === "login")
            next({name: "public"});
        else {
            next()
        }
    } else {
        if (to.name !== "login") next({name: "login"});
        else next();
    }
});


export default router;
