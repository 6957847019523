import StatisticsPostModel from './models/statistics-post.model';

export default {
    name: "statistics",
    data() {
        return {
            statistics: {}
        }
    },
    methods: {
        getStatistics() {
            this.$store.dispatch('statistics/getItems').then(() => {
                let item = this.$store.getters['statistics/_getItem'];
                if (item) {
                    this.statistics = item;
                }
            });
        },
        updateStatistics() {
            let item = this.$store.getters['statistics/_getItem'];
            let payload = new StatisticsPostModel(this.statistics)
            if (item) {
                this.$store.dispatch('statistics/updateItem', payload);
            } else {
                this.$store.dispatch('statistics/setItem', payload).then((res)=>{
                    if(res) {
                        this.getStatistics();
                    }
                });
            }
        }
    },
    created() {
        this.getStatistics();
    }
};