export default class StatisticsPostModel {
    constructor(item) {
        this._setId(item);
        this._setProjects(item);
        this._setBuildings(item);
        this._setCustomers(item);

    }
    /**
     * set id
     * @param id
     * @private
     */
    _setId({id}) {
        this.id = id;
    }

    /**
     * set projects
     * @param projects
     * @private
     */
    _setProjects({projects}) {
        this.finishedProjectCount = projects;
    }

    /**
     * set buildings
     * @param buildings
     * @private
     */
    _setBuildings({buildings}) {
        this.finishedBuildingCount = buildings;
    }

    /**
     * set customers
     * @param customers
     * @private
     */
    _setCustomers({customers}) {
        this.customerCount = customers;
    }

}