import SkillsPostModel from "@/views/skills/models/skills-post.model";
import getLanguages from "@/mixins/get-languages";

export default {
    name: "skills-add",
    data() {
        return {
            item: {}
        }
    },
    computed: {
      isEditPage() {
          return this.$route.meta.edit;
      }
    },
    methods: {
        approve() {
            let data = new SkillsPostModel(this.item);

            if(this.isEditPage) {
                this.$store.dispatch("skills/updateItem", data);
            }
            else {
                this.$store.dispatch("skills/setItem", data).then((res) => {
                    if (res) {
                        this.item = {};
                    }
                });
            }
        },
    },
    created() {
        let id = this.$route.params.id;
        if (this.isEditPage) {
            this.$store.dispatch("skills/getItemById", id).then((res) => {
                if (res) {
                    this.item = res;
                }
            });
        }
    },
    mixins: [getLanguages]

};
